const teamMemberData = [
  {
    fbLink: "",
    twLink: "",
    skLink: "skype:live:.cid.a7fdf6c1677665",
    liLink: "https://www.linkedin.com/in/ramswaroop-bajiya-b852611a9/",
    imgSrc: "https://i.ibb.co/JC4skS0/team-animate.jpg",
    name: "RAM SWAROOP BAJIYA",
    position: "FOUNDER & CEO",
  },
  {
    fbLink: "",
    twLink: "",
    skLink: "skype:live:.cid.c50792b864435cb3",
    liLink: "https://www.linkedin.com/in/surendra-singh-shekhawat-b35ba285",
    imgSrc: "https://i.ibb.co/JC4skS0/team-animate.jpg",
    name: "SURENDRA SINGH SHEKAWAT",
    position: "ADMINISTRATOR",
  },
  {
    fbLink: "",
    twLink: "",
    skLink: "skype:live:.cid.acb8c26f76ace709",
    liLink: "https://www.linkedin.com/in/sanjay-kverma",
    imgSrc: "https://i.ibb.co/JC4skS0/team-animate.jpg",
    name: "SANJAY VERMA",
    position: "SEO DEPARTMENT MANAGER",
  },
  {
    fbLink: "",
    twLink: "",
    skLink: "skype:maheshkumawat12",
    liLink: "https://www.linkedin.com/in/mahesh-kk-%E2%9C%94-732869192",
    imgSrc: "https://i.ibb.co/JC4skS0/team-animate.jpg",
    name: "MAHESH KUMAWAT",
    position: "DIGITAL MARKETING HEAD",
  },
  {
    fbLink: "",
    twLink: "",
    skLink: "skype:live:.cid.4b67740bf456ee0e",
    liLink: "https://www.linkedin.com/in/mukesh-saini-a54a3396",
    imgSrc: "https://i.ibb.co/JC4skS0/team-animate.jpg",
    name: "MUKESH SAINI",
    position: "TEAM LEADER",
  },
  {
    fbLink: "",
    twLink: "",
    skLink: "skype:live:.cid.3b5e5da9566884c1",
    liLink: "https://www.linkedin.com/in/pankaj-swami-vaishnav",
    imgSrc: "https://i.ibb.co/JC4skS0/team-animate.jpg",
    name: "PANKAJ VAISHNAV",
    position: "SOFTWARE ENGINEER",
  },
  {
    fbLink: "",
    twLink: "",
    skLink: "skype:live:.cid.3b5e5da9566884c1",
    liLink: "https://www.linkedin.com/in/pankaj-swami-vaishnav",
    imgSrc: "https://i.ibb.co/JC4skS0/team-animate.jpg",
    name: "PANKAJ VAISHNAV",
    position: "SOFTWARE ENGINEER",
  },
  {
    fbLink: "",
    twLink: "",
    skLink: "",
    liLink: "",
    imgSrc: "https://i.ibb.co/JC4skS0/team-animate.jpg",
    name: "PANKAJ SWAMI VAISHNAV",
    position: "SOFTWARE ENGINEER",
  },
  {
    fbLink: "",
    twLink: "",
    skLink: "",
    liLink: "",
    imgSrc: "https://i.ibb.co/JC4skS0/team-animate.jpg",
    name: "PANKAJ SWAMI VAISHNAV",
    position: "SOFTWARE ENGINEER",
  },
  {
    fbLink: "",
    twLink: "",
    skLink: "",
    liLink: "",
    imgSrc: "https://i.ibb.co/JC4skS0/team-animate.jpg",
    name: "PANKAJ SWAMI VAISHNAV",
    position: "SOFTWARE ENGINEER",
  },
  {
    fbLink: "",
    twLink: "",
    skLink: "",
    liLink: "",
    imgSrc: "https://i.ibb.co/JC4skS0/team-animate.jpg",
    name: "PANKAJ SWAMI VAISHNAV",
    position: "SOFTWARE ENGINEER",
  },
  {
    fbLink: "",
    twLink: "",
    skLink: "",
    liLink: "",
    imgSrc: "https://i.ibb.co/JC4skS0/team-animate.jpg",
    name: "PANKAJ SWAMI VAISHNAV",
    position: "SOFTWARE ENGINEER",
  },
];

export default teamMemberData;
